import * as React from "react";
import Layout from "../../components/Layout";

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Layout 
        pageTitle="Contact" 
        pageDescription="Contact Page that collects feedbacks from website users of jvictoriacollege.ca"
        pageSlug="/contact"
      >
        <section className="section">
          <div className="container">
            <div className="content">
              <h1>Contact</h1>
              <iframe 
                src="https://docs.google.com/forms/d/e/1FAIpQLScd04LiFDFoVPBIY7I3Tz5frsU2Ru7WcvdaJflbzX_VBzDWNg/viewform?embedded=true" 
                width="100%" 
                height="1600" 
                frameborder="0" 
                marginheight="0" 
                marginwidth="0"
                title="Contact Us Form - www.jvictoriacollege.ca"
              >
                Loading…
              </iframe>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
